import React from "react";
import { Navigate, useParams } from "react-router-dom";

function Redirect() {
    const {tenant, business} = useParams();
    return (
        <Navigate to={`/customer-verify/${tenant}/${business}`} />
    )
}

export default Redirect;