import axios from "axios";
import config from "../config";
const { ruleServerUrl } = config;

export const sendLink = (referFriend) => {
  return axios
    .post(`${ruleServerUrl}/referral/save`, referFriend)
    .then((response) => response.data)
    .catch((err) => {
      return err;
    });
};
