import axios from "axios";
import jwt_decode from "jwt-decode";
import { trim } from "lodash";
import { createContext, useLayoutEffect, useMemo } from "react";
import STRING_CONSTANTS from "../shared/constants/StringConstants";

const AuthContext = createContext({
  user: null,
  token: null,
});
const getPwaToken = () => {
  const params = new URLSearchParams(window.location.search);
  if (params.has(STRING_CONSTANTS.TOKEN_PARAM_KEY)) {
    document.cookie = `${STRING_CONSTANTS.COOKIE_NAME}=${params.get(
      STRING_CONSTANTS.TOKEN_PARAM_KEY
    )};path=/;max-age=${30 * 24 * 60 * 60}`;
    return params.get(STRING_CONSTANTS.TOKEN_PARAM_KEY);
  }
  const token = document.cookie.split(";").reduce((acc, val) => {
    const [key, value] = val.split("=");
    if (trim(key) === STRING_CONSTANTS.COOKIE_NAME) acc = value;
    return acc;
  }, null);
  console.log("token-from-cookie", token);
  return  token;
};

const getUserFromToken = (token) => {
  if (token !== null) return JSON.parse(jwt_decode(token).data || '{}');
};
export const AuthProvider = ({ children }) => {
  const token = useMemo(getPwaToken, []);
  const user = useMemo(() => getUserFromToken(token), [token]);
  useLayoutEffect(() => {
    if (token !== null) {
      axios.interceptors.request.use((config) => {
        config.params = {
          token,
          ...config.params,
        };
        return config;
      });
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ token, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
