import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AppConfigProvider } from "./context/AppConfig";
import { AppThemeProvider } from "./context/AppTheme";
import { AuthProvider } from "./context/Auth";
import { Toaster } from "react-hot-toast";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as manifest from "./manifest";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <AppThemeProvider>
    <BrowserRouter>
      <AuthProvider>
        <AppConfigProvider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </AppConfigProvider>
      </AuthProvider>
    </BrowserRouter>
    <Toaster position="top-right" containerStyle={{ top: 80 }} />
  </AppThemeProvider>
);

manifest.initialize();
serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration?.waiting) {
      await registration.unregister();
      // Makes Workbox call skipWaiting()
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      // Once the service worker is unregistered, we can reload the page to let
      // the browser download a fresh copy of our app (invalidating the cache)
      alert("New Version Of the App Detected!");
      window.location.reload();
    }
  },
});
