import axios from "axios";
import { assign, find, head } from "lodash";

import config from "../config";

const { ruleServerUrl } = config;

export const getPwaConfig = () => {
  return axios
    .get(`${ruleServerUrl}/pwa`)
    .then((response) => includeSelectedConfigData(response.data));
};

export const getConfig = (business) => {
  return axios
    .get(`${ruleServerUrl}/pwa?business=${business}`)
    .then((response) => includeSelectedConfigData(response.data, business));
};

const includeSelectedConfigData = (config, business) => {
  const businesses = config?.businesses;
  const selecteBussines = business
    ? find(businesses, (b) => b?.name === business)
    : head(businesses);
  return assign(config, {
    selecteBussines: selecteBussines,
  });
};
