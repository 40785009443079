import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useState, useMemo } from "react";
import * as Yup from "yup";
import useAppTheme from "../../hooks/useAppTheme";
import { sendLink } from "../../service/referFriend.service";
import STRING_CONSTANTS from "../../shared/constants/StringConstants";
import ReferedFriendTable from "./List";
const validationToReferFriend = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  mobileNumber: Yup.string()
    .min(10, "Mobile Number must be 10 at minimum")
    .max(10, "Mobile Number must be 10 at maximum")
    .required("Mobile Number is required"),
});

const Form = () => {
  const { theme } = useAppTheme();
  const [referedFriendsMap, setReferedFriendsMap] = useState({});
  let color = theme.palette.primary.main;
  const formik = useFormik({
    initialValues: {
      name: "",
      mobileNumber: "",
    },
    validationSchema: () => validationToReferFriend,
    onSubmit: () => {
      const { name, mobileNumber } = values;
      let data = {
        [mobileNumber]: {
          name,
          mobileNumber,
        },
      };
      if (!referedFriendsMap[mobileNumber]) {
        setReferedFriendsMap((referedFriend) => ({
          ...referedFriend,
          ...data,
        }));
        sendLink(values);
        clearall();
      } else {
        setErrors({ mobileNumber: "Already exists" });
      }
    },
  });
  const {
    values,
    setErrors,
    resetForm,
    handleSubmit,
    handleChange,
    errors,
    touched,
  } = formik;

  const referedFriend = useMemo(
    () => Object.values(referedFriendsMap),
    [referedFriendsMap]
  );
  const clearall = () => {
    resetForm();
  };
  const [value, setValue] = useState("yes");

  const handleradio = (event) => {
    setValue(event.target.value);
  };
  return (
    <>
      <div
        className="frdBox"
        style={{
          backgroundColor: color,
        }}
      >
        <Box style={{ position: "relative", top: "2%", width: "75%" }}>
          <Box style={{ textAlign: "center" }}>
            <Typography variant="h6">Lets Start Your Referral?</Typography>
            <FormControl>
              <RadioGroup
                row
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleradio}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      sx={{
                        "&, &.Mui-checked": {
                          color: "black",
                        },
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      sx={{
                        "&, &.Mui-checked": {
                          color: "black",
                        },
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <div
            style={{
              marginTop: 20,
              padding: 30,
              backgroundColor: "white",
              textAlign: "center",
            }}
          >
            <form onSubmit={handleSubmit} noValidate>
              <TextField
                sx={{ mb: 4, width: "100%" }}
                required
                label="Name"
                name="name"
                value={values.name || ""}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                  style: { color: "black" },
                }}
                error={Boolean(
                  errors.name && touched.name ? errors.name : null
                )}
                helperText={errors.name && touched.name ? errors.name : null}
              />
              <br />
              <TextField
                sx={{ mb: 4, width: "100%" }}
                type="number"
                label="Mobile Number"
                required
                name="mobileNumber"
                value={values.mobileNumber || ""}
                InputLabelProps={{
                  shrink: true,
                  style: { color: "black" },
                }}
                onChange={handleChange}
                error={Boolean(
                  errors.mobileNumber && touched.mobileNumber
                    ? errors.mobileNumber
                    : null
                )}
                helperText={
                  errors.mobileNumber && touched.mobileNumber
                    ? errors.mobileNumber
                    : null
                }
              />
              <br />
              <Button variant="contained" type="submit">
                {STRING_CONSTANTS.INVITE}
              </Button>
            </form>
          </div>
        </Box>
      </div>
      <div style={{ textAlign: "center" }}>
        {referedFriend?.length ? (
          <ReferedFriendTable referedFriend={referedFriend} />
        ) : (
          <Typography
            variant="h3"
            style={{ margin: "20%", color: "lightgray" }}
          >
            {STRING_CONSTANTS.INVITE_FRIENDS}
          </Typography>
        )}
      </div>
    </>
  );
};

export default Form;
