import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  referedFriendData,
  referedFriendTableHead,
} from "../../shared/constants/TableConstants";
import { StyledTableCell, StyledTableRow } from "../../styles/styledComponent";
import STRING_CONSTANTS from "../../shared/constants/StringConstants";

const tablevalue = (row) => {
  return referedFriendData.dataval.map((key, index) => {
    return (
      <StyledTableCell key={index + 1} align="center">
        {row[key]}
      </StyledTableCell>
    );
  });
};

const List = ({ referedFriend }) => {
  return (
    <div style={{ textAlign: "left", padding: 15 }}>
      <Typography variant="h6" sx={{ mt: 14, mb: 3, fontWeight: 600 }}>
        {STRING_CONSTANTS.REFERED_FRIEND}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            {referedFriendTableHead.heading.map((val) => {
              return (
                <StyledTableCell key={val} align="center">
                  {val}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {referedFriend?.length &&
            referedFriend.map((friend, index) => (
              <StyledTableRow key={index + 1}>
                {tablevalue(friend)}
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default List;
