import { Box, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { createContext, useState } from "react";
import useAuth from "../hooks/useAuth";
import { getUserLoyaltyData  } from "../service/loyaltyinfo.service";
import { getConfig } from "../service/pwaConfig";
import { get } from "lodash";
import { getFormatErrors } from "../shared/utils"

const AppConfigContext = createContext({
  appConfig: null,
  loyaltyDetails: null,
});

export const AppConfigProvider = ({ children }) => {
  const authData = useAuth();

  const [appConfig, setAppConfig] = useState(null);
  const [loyaltyDetails, setLoyaltyDetails] = useState(null);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ;(async () => {
      const business = get(authData, "user.business")
      try {
        if (!business) return
        const appConfig = await getConfig(business)
        setAppConfig(() => appConfig)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
        business &&
          (await getUserLoyaltyData(business)
            .then(setLoyaltyDetails)
            .catch((e) => setErrors({loyalty : getFormatErrors(e)})))
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <Box className="loader">
        <CircularProgress />
      </Box>
    );

  return (
    <AppConfigContext.Provider
      value={{ appConfig, setAppConfig, loyaltyDetails, errors }}
    >
      {children}
    </AppConfigContext.Provider>
  )
};

export default AppConfigContext;
