import axios from "axios";
import config from "../config";
const { ruleServerUrl } = config;

export const sendOtp = async (mobileNumber, tenant, businessUnit) => {
    const url = `${ruleServerUrl}/flow-otp/generate-for/custom-otp-auth`
    const response = await axios.post(url, {
            "mobile": mobileNumber,
            "tenantBrandBusinessId": null,
            "tenantId": null,
            "tenantName": tenant,
            "businessName": businessUnit
        })
    return response.data;
} 

export const verifyOtp = async (mobileNumber, tenant, businessUnit, otp) => {
    const url = `${ruleServerUrl}/flow-otp/verify-for/custom-otp-auth`
    const response = await axios.post(url, {
            "mobile": mobileNumber,
            "tenantBrandBusinessId": null,
            "tenantId": null,
            "otp": otp,
            "tenantName": tenant,
            "businessName": businessUnit
        })
        return response.data;
}


export const validateCustomer = async (mobile, tenant, business) => {
    const url = `${ruleServerUrl}/pwa/get-register-redirect?mobile=${mobile}&tenant=${tenant}&business=${business}`;
    const response = await axios.get(url)
    return response.data
}